/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./header";
// import './reset.css'
import "./layout.scss";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <div className="">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Header />
      <div>
        <main>{children}</main>
        <footer>
          <section style={{ marginBottom: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-4 footer-col">
                  <div className="bold footerItem">Contact</div>
                  <div className="footerItem">03 67 98 65 62</div>
                  <div className="footerItem">contact@jundtwurtz.com</div>
                  <div className="footerItem">
                    <a href="https://www.facebook.com/AdeleetMathieu/">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/explore/tags/patisseriejundtwurtz/">
                      <img
                        src={insta}
                        alt="Instagram"
                        style={{ marginLeft: "8px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-4 footer-col">
                  <div className="">4, rue Albert Schweitzer</div>
                  <div className="">67550 Eckwersheim</div>
                  <div className="">France</div>
                </div>
                <div className="col-md-4 footer-col">
                  <div className="bold footerItem">Horaires d'ouverture</div>
                  <div className="">
                    Du mardi au vendredi de 6h-13h / 16h-18h30
                  </div>
                  <div className="">Samedi 6h - 13h</div>
                  <div className="">Dimanche 7h - 12h</div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
