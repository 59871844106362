import React, { useState } from "react";
import logo from "../images/logo.png";
import { Navbar, Nav, NavDropdown, Modal } from "react-bootstrap";
import mag from "../images/travaux.jpeg";

const Header = () => {
  return (
    <header>
      <Navbar expand="lg" className="headerContainer">
        <div className="container">
          <Navbar.Brand href="/">
            <img src={logo} className="logo" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="nav-item">
              <Nav.Link className="nav-item" href="/">
                Accueil
              </Nav.Link>
            </div>
            <NavDropdown
              menuRole="menu"
              title="Nos créations"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/pain">Pains</NavDropdown.Item>
              <NavDropdown.Item href="/patisserie">
                Pâtisseries
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/chocolat">Chocolats</NavDropdown.Item> */}
              <NavDropdown.Item href="/glace">Glaces</NavDropdown.Item>
              <NavDropdown.Item href="/traiteur">Traiteurs</NavDropdown.Item>
            </NavDropdown>
            <div className="nav-item">
              <Nav.Link href="/contact">Contact</Nav.Link>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};
export default Header;
